<template>
    <v-container fluid tag="section" class="mt-4 bg-white border rounded shadow">

        <v-row justify="center">
            <v-col md="12">
                <v-tabs :grow="true" background-color="white" color="#d9242a">
                    <v-tab>Activos</v-tab>
                    <v-tab>Liquidados</v-tab>
                    <v-tabs-slider color="#d9242a"></v-tabs-slider>

                    <!-- pestaña de prestamos activos -->
                    <v-tab-item>
                        <v-row v-if="showTable">
                            <v-col cols="3" sm="3">
                                <v-btn depressed small color="error" @click="reporte_prestamo(1)">GENERAR PDF</v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col v-if="showTable" cols="12" sm="12">
                                <v-client-table :data="activo" :columns="columns" :options="options" />
                            </v-col>

                            <v-col v-if="showAlert" cols="12" sm="12">
                                <v-alert align="center" dense type="info"  style="padding:0px">
                                    <strong>No hay préstamos activos</strong>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <!-- pestaña de prestamos liquidados -->
                    <v-tab-item>
                        <v-row v-if="showTableLiquidados">
                            <v-col cols="3" sm="3">
                                <v-btn depressed small color="error" @click="reporte_prestamo(2)">GENERAR PDF</v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col v-if="showTableLiquidados" cols="12" sm="12">
                                <v-client-table :data="liquidados" :columns="columnsLiquido" :options="options" />
                            </v-col>
                            <v-col v-if="showAlertLiquidados" cols="12" sm="12">
                                <v-alert align="center" dense type="info"  style="padding:0px">
                                    <strong>No hay préstamos liquidados</strong>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data:function(){
        return {
            showTable:false,
            showAlert:false,

            showTableLiquidados:false,
            showAlertLiquidados:false,

            // datos tabla
            columns: ["index","deduccion","importe_total", "importe_pagado", 'saldo_insulto', "fecha_inicio","quincenas_pagadas"],
            columnsLiquido: ["index","deduccion","importe_total", "importe_pagado", "fecha_inicio", 'fecha_final_pago',"quincenas_pagadas"],
            options: {
                headings: {
                    index: '#',
                    deduccion: "DEDUCCIÓN",
                    importe_total: "TOTAL",
                    importe_pagado: "PAGADO",
                    saldo_insulto: 'SALDO',
                    fecha_inicio : "FECHA INICIO",
                    fecha_final_pago : 'FECHA FIN',
                    quincenas_pagadas: "QUINCENAS",
                },
                sortable: [],
                texts: {
                    count:"",
                    filter:"Buscar:",
                    filterPlaceholder: 'Buscar...',
                    limit:"Resultados:",
                    recordsDisplay: 'Resultados',
                    page:"Página:",
                    noResults:"No hay resultados",
                    filterBy: 'Buscar...',
                    loading:'Cargando...',
                    defaultOption: 'Seleccionar {column}'
                },
                filterable: false,
                filterByColumn: false,
                resizableColumns: false,
                sendEmptyFilters :false,
                childRowTogglerFirst: true,
                showChildRowToggler: true,
                perPage: 5,
                perPageValues: [5, 10, 15, 20],
                templates:{
                    index:(h,row,index) => {
                        return index;
                    },
                    importe_total: (h, row) =>{
                        return this.format_number( (row.importe_total) );
                    },
                    importe_pagado: (h, row) =>{
                        return this.format_number( row.importe_pagado );
                    },
                    saldo_insulto: (h, row) =>{
                        return this.format_number( (row.importe_total - row.importe_pagado).toFixed(2));
                    },
                    fecha_final_pago: (h, row) =>{
                        return (row.fecha_final_pago == null) ? 'SIN PAGAR' : row.fecha_final_pago;
                    },
                    quincenas_pagadas:(h, row, index) => {
                        return row.quincenas_pagadas+"/"+row.cantidad_quincenas;
                    }

                }
            },

            //variables
            activo: [],
            liquidados: []
        }
    },
    computed:{
        ...mapState(["user"]),
        ...mapState(["token"]),
        ...mapState(["baseUrl"])
    },
    async mounted(){
        await this.loadPrestamos();
        this.$NProgress.done();
    },
    methods:{
        async loadPrestamos(){
            try{

                let response = await this.$http.get("get-prestamos/"+this.user.id_empleado);

                this.activo = response.data.data.activos;
                this.liquidados = response.data.data.liquidados;

                if (this.activo){
                    this.showTable = true;
                }else{
                    this.showAlert = true
                }

                if (this.liquidados){
                    this.showTableLiquidados = true;
                }else{
                    this.showAlertLiquidados = true;
                }
            }catch(error){
                this.showAlert = true;
            }
        },
        reporte_prestamo: function(estado){
            const form = document.createElement('form');
            form.target = "_blank";
            form.method = 'post';
            form.id = 'form';
            form.action = `${this.baseUrl}reporte-prestamos?token=${this.token}`;

            const empleado = document.createElement('input')
            empleado.type = 'hide';
            empleado.name = 'empleado';
            empleado.value = this.user.id_empleado;
            form.append(empleado);

            const tipo_reporte = document.createElement('input')
            tipo_reporte.type = 'hide';
            tipo_reporte.name = 'estado';
            tipo_reporte.value = estado;

            form.append(tipo_reporte);
            document.querySelector('body').append(form);
            //-----------------------------------------------------------------------------------------------------
            form.submit();
            document.querySelector('body').removeChild(document.getElementById('form'))
        },
        format_number: function(total){ // funcion que agrega comas en caso de que el numero sea millar

            total += '';
            let numero = total.split(".");
            let entero = numero[0];
            let decimal = numero[1];
            let entero_fomateado = Number(entero).toLocaleString();
            let resultado = `${entero_fomateado}.${decimal}`;
            let final = `$${resultado}`;
            return final;

        },
    }
}
</script>